import styled from 'styled-components';

export default styled.label`
  display: block;
  width: 100%;
  position: relative;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1rem;

    > p {
      margin: 0;
      padding-right: 2.5rem;
      line-height: 1.25em;
    }

    .field {
      border-bottom: solid 1px ${props => props.theme.colors.grey4};
      width: 100%;
      max-width: 135px;
      display: flex;
      flex-direction: row;

      p {
        margin: 0;

        &:first-child {
          padding-right: 0.25rem;
        }
      }

      input {
        margin: 0;
        outline: none;
        border: none;
        background: transparent;
        font-size: ${props => props.theme.type.paragraph.intro};
        line-height: 1em;
        letter-spacing: ${props => props.theme.letterSpacing.paragraph};
        font-family: ${props => props.theme.fonts.primary};
        font-weight: normal;
        width: 100%;
      }
    }
  }

  .slider {
    position: relative;

    .fill {
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
      height: 9px;
      border-radius: 5px;
      z-index: 1;
      overflow: hidden;
    }

    .upper {
      height: 100%;
      width: 100%;
      background: ${props => props.theme.colors.vmfBlue};
      transform-origin: 0 0 ;
      position: absolute;
      top: 0;
      left: 0;
    }

    .lower {
      height: 100%;
      width: 100%;
      background: ${props => props.theme.colors.grey5};
    }
  }

  input[type='range'] {
    width: 100%;
    appearance: none;
    background: transparent;
    height: 9px;
    border-radius: 5px;
    position: relative;
    z-index: 3;

    &::-webkit-slider-thumb {
      appearance: none;
      height: 30px;
      width: 30px;
      background: ${props => props.theme.colors.white};
      border: solid 1px ${props => props.theme.colors.vmfBlue};
      border-radius: 100%;
      cursor: pointer;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        height: 20px;
        width: 20px;
      }
    }

    &::-webkit-slider-runnable-track {
      /* border-radius: 10px;
      height: 9px; */
      /* background: linear-gradient(
        to right,
        ${props => props.theme.colors.vmfBlue},
        ${props => props.theme.colors.vmfBlue} ${props => props.percent}%,
        transparent ${props => props.percent}%,
        transparent
      ); */
    }

    &:focus {
      outline: none;
    }
  }

  .error {
    position: absolute;
    color: ${props => props.theme.colors.vmfRed};
    font-size: ${props => props.theme.type.paragraph.xs};
    margin: .5em 0;
  }
`;
