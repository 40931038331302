import styled from 'styled-components';

export default styled.section`
  padding: 3rem 1rem;

  .inner {
    margin: auto;
    max-width: ${props => props.theme.maxWidths.containerSmall};

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.calculator};
    }
  }

  .calculator {
    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .fields {
    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      width: calc(50% - 0.5rem);
      max-width: 390px;
    }

    label {
      margin: 0;
      padding: 1.5rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .results {
    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-grow: 1;
      max-width: 600px;
      margin-left: 3rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktopLarge}) {
      margin-right: -6rem;
      margin-left: 0;
    }

    footer {
      margin-top: 2rem;

      u {
        text-transform: uppercase;
      }
    }
  }
`;
