import React from 'react';
import Styles from './new.styles';

const Calculator = ({
  fields = null,
  results = null,
  content = null,
  disclaimer = null,
  className = '',
}) => (
  <Styles className={className}>
    <section className='inner'>
      <section className='calculator'>
        <section className='fields'>{fields}</section>
        <section className='results'>
          {results}
          {disclaimer && <footer>{disclaimer}</footer>}
        </section>
      </section>
      {content && <section className='content'>{content}</section>}
    </section>
  </Styles>
);

Calculator.propTypes = {};

export default Calculator;
