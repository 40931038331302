import round from './round';
import calculatePayment from './calculatePayment';

/**
 * Calculate payments of mortgage
 * @param {Number} loanLength Loan length in years
 * @param {Number} interestRate Interest rate (ex: 8.0)
 * @param {Number} loanAmount Loan amount in dollars
 * @param {Number} annualTax Annual tax in dollars
 * @param {Number} annualInsurance Annual insurance in dollars
 */
export function monthlyPaymentCalculator(
  loanLength,
  interestRate,
  loanAmount,
  annualTax,
  annualInsurance
) {
  const monthlyRate = interestRate / 1200.0;
  const numberOfPayments = loanLength * 12.0;

  const monthlyPrincipalAndInterest = calculatePayment(
    loanAmount,
    monthlyRate,
    numberOfPayments
  );

  const monthlyTax = annualTax / 12.0;
  const monthlyInsurance = annualInsurance / 12.0;
  const totalMonthlyPayment =
    monthlyPrincipalAndInterest + monthlyTax + monthlyInsurance;

  return {
    monthlyPrincipalAndInterest: round(monthlyPrincipalAndInterest),
    monthlyTax: round(monthlyTax),
    monthlyInsurance: round(monthlyInsurance),
    totalMonthlyPayment: round(totalMonthlyPayment),
    error: null,
  };
}

export default monthlyPaymentCalculator;
