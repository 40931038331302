import React from 'react';
import Styles from './styles';

export default ({ title = '', label = null, pieChart = null, legend = [] }) => (
  <Styles>
    <header>{title}</header>
    <section className='content'>
      <header>
        <section className='label'>{label}</section>
        <section className='chart'>{pieChart}</section>
      </header>
      <ul>
        {legend.map(({ title, color, value }) => (
          <li key={title}>
            <section className='color' style={{ background: color }} />
            <p className='title'>{title}</p>
            <p className='value'>{value}</p>
          </li>
        ))}
      </ul>
    </section>
  </Styles>
);
