import React from 'react';
import noop from '../../../util/noop';
import Styles from './styles';
import Tooltip from '../Tooltip';

const Slider = ({
  label = '',
  name = '',
  min = 0,
  max = 1000,
  step = 1,
  value = '0',
  prefix = '',
  suffix = '',
  tooltip = '',
  error = false,
  onChange = noop,
}) => (
  <Styles className='slider'>
    <header>
      <p>
        {label}{' '}
        {tooltip && (
          <Tooltip>
            <p className='type-caption'>{tooltip}</p>
          </Tooltip>
        )}
      </p>
      <section className='field'>
        {prefix && <p>{prefix}</p>}
        <input
          type='text'
          name={name}
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
        {suffix && <p>{suffix}</p>}
      </section>
    </header>
    <section className='slider'>
      <input
        type='range'
        name={name}
        min={min}
        max={max}
        step={step}
        value={parseFloat(`${value}`.replace(/,/g, '')) || 0}
        onChange={onChange}
      />
      <div className='fill'>
        <div
          className='upper'
          style={{
            transform: `scaleX(${
              (parseFloat(`${value}`.replace(/,/g, '')) - min) / (max - min) ||
              0
            })`,
          }}
        />
        <div className='lower' />
      </div>
    </section>
    {error && <p className='error'>{error}</p>}
  </Styles>
);

Slider.propTypes = {};

export default Slider;
